// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px; /* Margin bottom by footer height */
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
}

.my-card {
    cursor: pointer;
    background-color: #FACA4B;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #212529;
    color: white;
}

.nav-pills .nav-link {
    color: white;
}

a {
    text-decoration: none;
}

.own_breadcrumb {
    color: #F89C56;
}

.own_header {
    color: #F89C56;
}

.dark_background {
    background-color: #18323B;
    padding: 10px;
    border-radius: 10px;
    color: white;
    overflow: auto;

}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #184d61;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #09678e;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #F89C56;
}

a {
    color: #F89C56;

    &:hover {
        color: #f37202;
    }
}

.fa-check {
    color: #F89C56;
}

.page-item.active .page-link {
    background-color: #F89C56;
    border-color: #F89C56;
}

.page-link {
    color: #F89C56;
}

.page-link:focus, .page-link:hover{
    color: #F89C56;
}

.dropdown-item:active {
    background-color: #F89C56;
}
